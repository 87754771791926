var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c(
      "form",
      [
        _c(
          "v-row",
          [
            !_vm.$v.form.isPrimary.$model
              ? _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        value: _vm.value.name,
                        label: "Name",
                        required: "",
                        "error-messages": _vm.handleErrors(_vm.$v.form.name),
                        readonly: _vm.readonly,
                        disabled: _vm.disabled,
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.$v.form.name.$touch()
                        },
                        input: _vm.handleInput,
                      },
                      model: {
                        value: _vm.$v.form.name.$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.form.name,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.form.name.$model",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.$v.form.isPrimary.$model
              ? _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        value: _vm.value.relationship,
                        label: "Relationship",
                        required: "",
                        "error-messages": _vm.handleErrors(
                          _vm.$v.form.relationship
                        ),
                        readonly: _vm.readonly,
                        disabled: _vm.disabled,
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.$v.form.relationship.$touch()
                        },
                        input: _vm.handleInput,
                      },
                      model: {
                        value: _vm.$v.form.relationship.$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.form.relationship,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.form.relationship.$model",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c("v-checkbox", {
                  attrs: {
                    value: _vm.value.isPrimary,
                    label: "Primary",
                    required: "",
                    disabled: _vm.disabled,
                  },
                  on: { change: _vm.handlePrimary },
                  model: {
                    value: _vm.$v.form.isPrimary.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.form.isPrimary, "$model", $$v)
                    },
                    expression: "$v.form.isPrimary.$model",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c("phone-number", {
                  attrs: {
                    value: _vm.value.phone,
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.$v.form.phone.$touch()
                    },
                    input: _vm.handleInput,
                  },
                  model: {
                    value: _vm.$v.form.phone.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.form.phone,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.form.phone.$model",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "2" } },
              [
                _c("v-checkbox", {
                  attrs: {
                    value: _vm.value.canText,
                    label: "Text",
                    required: "",
                    disabled: _vm.disabled,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$v.form.canText.$touch()
                    },
                  },
                  model: {
                    value: _vm.$v.form.canText.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.form.canText, "$model", $$v)
                    },
                    expression: "$v.form.canText.$model",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "2" } },
              [
                _c("v-text-field", {
                  attrs: {
                    value: _vm.value.ext,
                    label: "Extension",
                    required: "",
                    "error-messages": _vm.handleErrors(_vm.$v.form.ext),
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.$v.form.ext.$touch()
                    },
                    input: _vm.handleInput,
                  },
                  model: {
                    value: _vm.$v.form.ext.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.form.ext,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.form.ext.$model",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "4" } },
              [
                _c("v-text-field", {
                  attrs: {
                    value: _vm.value.email,
                    label: "Email",
                    required: "",
                    "error-messages": _vm.handleErrors(_vm.$v.form.email),
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.$v.form.email.$touch()
                    },
                    input: _vm.handleInput,
                  },
                  model: {
                    value: _vm.$v.form.email.$model,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.$v.form.email,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.form.email.$model",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }