var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      attrs: {
        readonly:
          _vm.value.id > 0 &&
          !_vm.canEditSetupTables &&
          !_vm.me.is.driverTableOnly,
      },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _vm.canEditSetupTables || _vm.me.is.driverTableOnly
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "green", dark: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                  _vm.me.is.superAdmin && _vm.value.synced
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right mr-2",
                          attrs: {
                            color: "primary",
                            disabled: _vm.value.cannotDelete,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.hide()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [
                              _vm._v(
                                "mdi-" +
                                  _vm._s(_vm.value.hidden ? "eye" : "eye-off")
                              ),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.value.hidden ? "Unhide" : "Hide") +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _vm.value.synced
            ? _c(
                "v-alert",
                { attrs: { outlined: "", type: "info", text: "" } },
                [
                  _vm._v(
                    " This Driver is synced with EZRouting. Some fields will not be able to be modified here. "
                  ),
                ]
              )
            : !_vm.value.id && _vm.isRoutingClient
            ? _c(
                "v-alert",
                { attrs: { outlined: "", type: "info", text: "" } },
                [
                  _vm._v(
                    " This Driver will only be avilable in EZActivityTrips. If you would like this Driver to be available in EZRouting, please add them there first and they will be synced with EZActivityTrips. "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-expansion-panels",
            { attrs: { accordion: "", mandatory: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("General")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "5" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: _vm.value.synced,
                                  value: _vm.value.firstName,
                                  label: "First Name",
                                  required: "",
                                  hint: "*Required",
                                  "persistent-hint": !_vm.value.id,
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.firstName
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.firstName.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.firstName.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.firstName,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.firstName.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "5" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: _vm.value.synced,
                                  value: _vm.value.lastName,
                                  label: "Last Name",
                                  required: "",
                                  hint: "*Required",
                                  "persistent-hint": !_vm.value.id,
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.lastName
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.lastName.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.lastName.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.lastName,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.lastName.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "2" },
                            },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  readonly: _vm.value.synced,
                                  value: _vm.value.active,
                                  label: "Active",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.active.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.active.$model,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.$v.form.active, "$model", $$v)
                                  },
                                  expression: "$v.form.active.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "10" },
                            },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  value: _vm.value.email,
                                  items: _vm.userEmails,
                                  label: "Email",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.email.$touch()
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    _vm.$v.form.email.$model =
                                      $event.srcElement.value
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.email.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.email,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.email.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "mb-0",
                                attrs: {
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "Base Location",
                                  value: _vm.value.baseLocationId,
                                  items: _vm.locations,
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.baseLocationId
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.baseLocationId.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.baseLocationId.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.baseLocationId,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.baseLocationId.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  value: _vm.value.zone,
                                  items: _vm.zoneItems,
                                  label: "Zone",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.zone.$touch()
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    _vm.$v.form.zone.$model =
                                      $event.srcElement.value
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.zone.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.zone,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.zone.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "mb-0",
                                attrs: {
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: _vm.secondaryLocationLabel,
                                  value: _vm.value.secondaryLocationId,
                                  items: _vm.locations,
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.secondaryLocationId
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.secondaryLocationId.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.secondaryLocationId.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.secondaryLocationId,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.form.secondaryLocationId.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.assignedVehicles,
                                  label: "Assigned Vehicle(s)",
                                  hint: "You can assign Drivers to Vehicles in the Vehicles Table",
                                  "persistent-hint": "",
                                  readonly: "",
                                },
                              }),
                              _vm.upcomingAssignments?.driverId
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        outlined: "",
                                        type: "warning",
                                        text: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " This driver has an upcoming assignment with Trip Request #" +
                                          _vm._s(
                                            _vm.upcomingAssignments
                                              .tripRequestId
                                          ) +
                                          " on " +
                                          _vm._s(
                                            _vm.convertDateFormat(
                                              _vm.upcomingAssignments
                                                .vehPickupDate +
                                                " " +
                                                _vm.upcomingAssignments
                                                  .vehPickupTime
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "mb-0",
                                attrs: {
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "Vehicle Preference",
                                  value: _vm.value.vehiclePreference,
                                  items: _vm.vehiclePreferences,
                                  "item-text": "text",
                                  "item-value": "value",
                                  outlined: "",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.vehiclePreference
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.vehiclePreference.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.vehiclePreference.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.vehiclePreference,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.form.vehiclePreference.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  value: _vm.value.designation,
                                  items: _vm.designationItems,
                                  label: "Designation",
                                  readonly: _vm.value.synced,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.designation.$touch()
                                  },
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    _vm.$v.form.designation.$model =
                                      $event.srcElement.value
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.designation.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.designation,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.designation.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "5" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.trips,
                                  label: "Total Trips",
                                  type: "number",
                                  min: "0",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.trips.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.trips.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.trips,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.trips.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "5" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.hours,
                                  label: "Total Hours",
                                  type: "number",
                                  min: "0",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.hours.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.hours.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.hours,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.hours.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Addresses")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.addresses, function (address, i) {
                          return _c(
                            "v-col",
                            { key: i, attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("address-form", {
                                        ref: `aform-${i}`,
                                        refInFor: true,
                                        attrs: {
                                          value: address,
                                          showLatLng: false,
                                          multiple: "",
                                          disabled:
                                            address.deleted ||
                                            _vm.isRoutingClient,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInputAddress(
                                              $event,
                                              i
                                            )
                                          },
                                          handlePrimary: function ($event) {
                                            return _vm.handlePrimaryAddress(
                                              i,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "px-6" },
                                    [
                                      address.deleted
                                        ? _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteAddress(i)
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-undo"),
                                              ]),
                                              _vm._v(" Undo"),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "error" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteAddress(i)
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-delete"),
                                              ]),
                                              _vm._v(" Delete"),
                                            ],
                                            1
                                          ),
                                      _c("v-spacer"),
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle2" },
                                        [_vm._v("#" + _vm._s(i + 1))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.isRoutingClient,
                                  },
                                  on: { click: _vm.addAddress },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(" Add Address"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Contacts")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.contacts, function (contact, i) {
                          return _c(
                            "v-col",
                            { key: i, attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("contact-form", {
                                        ref: `cform-${i}`,
                                        refInFor: true,
                                        attrs: {
                                          value: contact,
                                          disabled:
                                            contact.deleted ||
                                            _vm.isRoutingClient,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.handleInputContact(
                                              $event,
                                              i
                                            )
                                          },
                                          handlePrimary: function ($event) {
                                            return _vm.handlePrimaryContact(
                                              i,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "px-6" },
                                    [
                                      contact.deleted
                                        ? _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteContact(i)
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-undo"),
                                              ]),
                                              _vm._v(" Undo"),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                disabled: _vm.isRoutingClient,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteContact(i)
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-delete"),
                                              ]),
                                              _vm._v(" Delete "),
                                            ],
                                            1
                                          ),
                                      _c("v-spacer"),
                                      _c(
                                        "div",
                                        { staticClass: "text-subtitle2" },
                                        [_vm._v("#" + _vm._s(i + 1))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.isRoutingClient,
                                  },
                                  on: { click: _vm.addContact },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(" Add Contact "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Rates")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.payRate,
                                  label: "Rate",
                                  type: "number",
                                  min: "0",
                                  "prepend-icon": "mdi-currency-usd",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.payRate
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.payRate.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.payRate.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.payRate,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.payRate.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.otRate,
                                  label: "OT Rate",
                                  type: "number",
                                  min: "0",
                                  "prepend-icon": "mdi-currency-usd",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.otRate
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.otRate.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.otRate.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.otRate,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.otRate.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.otherRate,
                                  label: "Other Rate",
                                  type: "number",
                                  min: "0",
                                  "prepend-icon": "mdi-currency-usd",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.otherRate
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.otherRate.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.otherRate.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.otherRate,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.otherRate.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Identification")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { value: _vm.value.fulltime, row: "" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.$v.form.fulltime.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.$v.form.fulltime.$model,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$v.form.fulltime,
                                        "$model",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "$v.form.fulltime.$model",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { label: "Full-Time", value: 1 },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "Part-Time", value: 0 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: _vm.value.synced,
                                  value: _vm.value.localId,
                                  label: "ID",
                                  "persistent-hint": !_vm.value.id,
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.localId
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.localId.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.localId.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.localId,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.localId.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: _vm.value.synced,
                                  value: _vm.value.SSN,
                                  label: "SSN",
                                  "persistent-hint": !_vm.value.id,
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.SSN
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.SSN.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.SSN.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.SSN,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.SSN.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("date-picker", {
                                attrs: {
                                  label: "Date of Birth",
                                  readonly: _vm.value.synced,
                                },
                                model: {
                                  value: _vm.$v.form.dob.$model,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.$v.form.dob, "$model", $$v)
                                  },
                                  expression: "$v.form.dob.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("date-picker", {
                                attrs: {
                                  label: "Hire/Sen Date",
                                  readonly: _vm.value.synced,
                                },
                                model: {
                                  value: _vm.$v.form.hireAt.$model,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.$v.form.hireAt, "$model", $$v)
                                  },
                                  expression: "$v.form.hireAt.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: _vm.value.retirement,
                                  label: "Retirement",
                                  required: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.retirement.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.retirement.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.retirement,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.retirement.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-subheader", [_vm._v("Supplemental Driver Dates")]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("date-picker", {
                                attrs: { label: "Temp/Perm" },
                                model: {
                                  value: _vm.$v.form.tempDate.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.tempDate,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.tempDate.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("date-picker", {
                                attrs: { label: "Sub" },
                                model: {
                                  value: _vm.$v.form.subDate.$model,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.$v.form.subDate, "$model", $$v)
                                  },
                                  expression: "$v.form.subDate.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("date-picker", {
                                attrs: { label: "Application" },
                                model: {
                                  value: _vm.$v.form.applicationDate.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.applicationDate,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.applicationDate.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v("Availability Preferences / Restrictions"),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _vm.config.prefLabel1.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0",
                                  attrs: { cols: "12", md: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-subtitle-2 ml-0" },
                                    [_vm._v(_vm._s(_vm.config.prefLabel1))]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        value: _vm.value.prefAM,
                                        row: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.$v.form.prefAM.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.$v.form.prefAM.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.form.prefAM,
                                            "$model",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "$v.form.prefAM.$model",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: { label: "Yes", value: 1 },
                                      }),
                                      _c("v-radio", {
                                        attrs: { label: "No", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.config.prefLabel2.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0",
                                  attrs: { cols: "12", md: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-subtitle-2 ml-0" },
                                    [_vm._v(_vm._s(_vm.config.prefLabel2))]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        value: _vm.value.prefMidDay,
                                        row: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.$v.form.prefMidDay.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.$v.form.prefMidDay.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.form.prefMidDay,
                                            "$model",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "$v.form.prefMidDay.$model",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: { label: "Yes", value: 1 },
                                      }),
                                      _c("v-radio", {
                                        attrs: { label: "No", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.config.prefLabel3.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0",
                                  attrs: { cols: "12", md: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-subtitle-2 ml-0" },
                                    [_vm._v(_vm._s(_vm.config.prefLabel3))]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        value: _vm.value.prefPM,
                                        row: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.$v.form.prefPM.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.$v.form.prefPM.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.form.prefPM,
                                            "$model",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "$v.form.prefPM.$model",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: { label: "Yes", value: 1 },
                                      }),
                                      _c("v-radio", {
                                        attrs: { label: "No", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.config.prefLabel4.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0",
                                  attrs: { cols: "12", md: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-subtitle-2 ml-0" },
                                    [_vm._v(_vm._s(_vm.config.prefLabel4))]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        value: _vm.value.prefOvernight,
                                        row: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.$v.form.prefOvernight.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.$v.form.prefOvernight.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.form.prefOvernight,
                                            "$model",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "$v.form.prefOvernight.$model",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: { label: "Yes", value: 1 },
                                      }),
                                      _c("v-radio", {
                                        attrs: { label: "No", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.config.prefLabel5.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0",
                                  attrs: { cols: "12", md: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-subtitle-2 ml-0" },
                                    [_vm._v(_vm._s(_vm.config.prefLabel5))]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        value: _vm.value.prefWeekend,
                                        row: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.$v.form.prefWeekend.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.$v.form.prefWeekend.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.form.prefWeekend,
                                            "$model",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "$v.form.prefWeekend.$model",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: { label: "Yes", value: 1 },
                                      }),
                                      _c("v-radio", {
                                        attrs: { label: "No", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.config.prefLabel6.length
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pb-0",
                                  attrs: { cols: "12", md: "4" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-subtitle-2 ml-0" },
                                    [_vm._v(_vm._s(_vm.config.prefLabel6))]
                                  ),
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: {
                                        value: _vm.value.prefNonSchool,
                                        row: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.$v.form.prefNonSchool.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.$v.form.prefNonSchool.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.form.prefNonSchool,
                                            "$model",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "$v.form.prefNonSchool.$model",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: { label: "Yes", value: 1 },
                                      }),
                                      _c("v-radio", {
                                        attrs: { label: "No", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.regHoursWeek,
                                  label: "Reg Hours/Week",
                                  type: "number",
                                  min: "0",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.regHoursWeek
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.regHoursWeek.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.regHoursWeek.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.regHoursWeek,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.regHoursWeek.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.value.regHoursDay,
                                  label: "Reg Hours/Day",
                                  type: "number",
                                  min: "0",
                                  "error-messages": _vm.handleErrors(
                                    _vm.$v.form.regHoursDay
                                  ),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.regHoursDay.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.regHoursDay.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.regHoursDay,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.regHoursDay.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v("Comments / Indicators"),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  value: _vm.value.comments,
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label: "Comments",
                                },
                                model: {
                                  value: _vm.$v.form.comments.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.comments,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.comments.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  value: _vm.value.indicators,
                                  items: _vm.indicatorItems,
                                  label: "Indicators",
                                  outlined: "",
                                  multiple: "",
                                  chips: "",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.$v.form.indicators.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.indicators.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.form.indicators,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.form.indicators.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "10" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.driverLogNotes,
                                      label:
                                        "Enter comment concerning driver to post to driver log",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.driverLogNotes,
                                      callback: function ($$v) {
                                        _vm.driverLogNotes =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "driverLogNotes",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { color: "primary" },
                                      on: { click: _vm.postDriverLog },
                                    },
                                    [_vm._v("Save")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }